import { TAB } from '@sweb-front/utils';
import { ButtonActionWrapper, StyledBackButtonWrapper } from '@vat/utils';
import { Loader } from '@sweb-front/components';
import styled from 'styled-components';

const OptinWrapper = styled.div`
  font-size: ${({ theme }) => theme.variables.layout.spacing.spacing_s};
  line-heigth: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  & a {
    color: var(--main-green-500);
  }
`;

export const ModalOptionWrapper = styled.div`
  display: flex;
  background: #fff;
  width: 100%;
  & #main-section {
    padding: 0;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 1.6rem;
  & gender-input {
    & radio-button-card {
      margin-right: 0;
      --content-padding: 2.4rem 1.6rem 1.6rem 1.6rem;
    }
  }
`;

export const DocumentsWrapper = styled.div`
  margin-bottom: 1.6rem;
`;

export const MainRadioWrapper = styled.div`
  margin-bottom: 0.8rem;
`;

export const RadioWrapper = styled.div`
  margin: 0 0 1.6rem 0;

  & div {
    width: 100%;
  }

  & .assurance {
    color: var(--main-green-500);
  }
`;

export const AsteriskWrapper = styled.div`
  margin-bottom: 1.6rem;
`;

export const CustomStyledBackButtonWrapper = styled(StyledBackButtonWrapper)`
  margin-top: 1.6rem;

  @media screen and ${TAB} {
    margin-top: 0;
  }
`;

export const CustomButtonActionWrapper = styled(ButtonActionWrapper)`
  margin-bottom: 0rem;
  @media screen and ${TAB} {
    margin-bottom: 0.8rem;
    margin-top: 0;
  }
`;

export const StyledLoader = styled(Loader)`
  margin: 10rem 0;
`;

export const AssuranceAdhesion = styled.div`
  margin-top: 1.6rem;
  font-family: 'Open Sans';
  color: var(--neutrals-400);
  font-size: 1.2rem;
  line-height: 1.4;

  & a {
    text-decoration: none;
    cursor: pointer;
    color: var(--main-green-500);
  }
`;

export const ErrorMessageWrapper = styled.div`
  color: var(--error-500);
  font-size: 1.2rem;
  margin: 0 0 2.4rem 0;
`;

export const CustomedBubleInfoWrapper = styled.div`
  margin-top: 1.6rem;
`;

export default OptinWrapper;
