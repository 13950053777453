import { Modal } from '@sweb-front/components';
import { ModalLeaveWrapper, StyledValidateButtonWrapper } from '@vat/utils';
import {
  ButtonLeaveModalWrapper,
  DescriptionWrapper,
  LeaveContentWrapper,
  SVGIconErrorWrapper,
  SVGIconReturnWrapper,
  TitleWrapper,
} from './styles';
import { t } from 'i18next';
import useModalLeave from './useModalLeave';
import { StyledButtonLoader } from '@sweb-front/styles';

export interface IMoodalLeaveProps {
  title: string;
  description: string;
  descriptionProgress: string;
  confirmation: string;
  close: () => void;
}

const ModalLeave = (props: IMoodalLeaveProps) => {
  const { returnToUrlWebMerchant, isLoading } = useModalLeave();
  return (
    <>
      <Modal
        isVisible={true}
        isFullScreen={false}
        isClosable={true}
        handleCloseModal={() => {
          if (!isLoading) {
            props.close();
          }
        }}
      >
        <ModalLeaveWrapper>
          <SVGIconErrorWrapper>
            <base-icon icon="warning" />
          </SVGIconErrorWrapper>
          <LeaveContentWrapper>
            <TitleWrapper level={3}>{props.title}</TitleWrapper>
            <DescriptionWrapper>
              {props.description}
              <span className="strong">{props.descriptionProgress}</span>
              {props.confirmation}
            </DescriptionWrapper>
          </LeaveContentWrapper>
          <ButtonLeaveModalWrapper>
            <StyledValidateButtonWrapper
              disabled={isLoading}
              onClick={props.close}
              id="modal-cancel-button"
            >
              {t('common.cancelLeave')}
            </StyledValidateButtonWrapper>
            <StyledValidateButtonWrapper
              onClick={returnToUrlWebMerchant}
              id="modal-edit-button"
            >
              {isLoading ? (
                <StyledButtonLoader
                  isLoading
                  isTextInline
                  isHideBackground={false}
                />
              ) : (
                <>
                  <SVGIconReturnWrapper></SVGIconReturnWrapper>
                  {t('common.backLeave')}
                </>
              )}
            </StyledValidateButtonWrapper>
          </ButtonLeaveModalWrapper>
        </ModalLeaveWrapper>
      </Modal>
    </>
  );
};

export default ModalLeave;
