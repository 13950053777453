import { useTranslation } from 'react-i18next';
import { Section } from '@sweb-front/components';
import useObConnexionError from './useObConnexionError';
import { useAppSelector } from '@sweb-front/store';
import {
  ObButtonAWrapper,
  ObContentWrapper,
  ObTitleWrapper,
  StyledContinueButtonWrapper,
} from './style';
import { StyledButtonLoader } from '@sweb-front/styles';
import { trackEvent } from '@sweb-front/utils';
import { useEffect } from 'react';
import { usePageRedirection } from '@sweb-front/hooks';
import { OBCONNEXIONERROR } from '@vat/configuration';
import { setIdHashed } from 'src/containers/forms/utils';

const ObConnexionError = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  const { onSubmit, isActionLoading } = useObConnexionError(opportunity);
  const { isPageLoading } = usePageRedirection(OBCONNEXIONERROR, {
    locked: true,
  });

  useEffect(() => {
    if (opportunity?.opportunityIdExt) {
      const { financialDetails } = opportunity?.offers?.[0]?.loans?.[0];
      trackEvent({
        event: 'pageLoaded',
        site: 'Ecommerce',
        workflow: parameters?.wayCd,
        pageName: 'E-Commerce : Open Banking : Connexion perdue',
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        Amount: (financialDetails?.overdraftAmt as number) ?? undefined,
        Rate: (financialDetails?.tncRt as number) ?? undefined,
        Term: (financialDetails?.term as number) ?? undefined,
        MonthlyPayment:
          (financialDetails?.monthlyPaymentWithoutInsuranceAmt as number) ??
          undefined,
        opportunityIdHashed: setIdHashed(opportunity),
        ContributorCode: Number(opportunity?.distributor?.distributorNb),
      });
    }
  }, []);

  return (
    !isPageLoading && (
      <>
        <Section title="" description="">
          <ObTitleWrapper>
            {t('openBanking.obConnexionError.title')}
          </ObTitleWrapper>

          <ObContentWrapper>
            {t('openBanking.obConnexionError.description')}
          </ObContentWrapper>
          <ObButtonAWrapper>
            <StyledContinueButtonWrapper onClick={onSubmit}>
              {isActionLoading ? (
                <StyledButtonLoader
                  isLoading
                  isTextInline
                  isHideBackground={false}
                />
              ) : (
                t('openBanking.obConnexionError.button')
              )}
            </StyledContinueButtonWrapper>
          </ObButtonAWrapper>
        </Section>
      </>
    )
  );
};

export default ObConnexionError;
