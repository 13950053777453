import {
  BackToWebMerchantWrapper,
  FooterBackToWebMerchant,
  SVGIconWrapper,
} from './style';

export type RedirectWebMerchantFooterProps = {
  onClick: () => void;
  text: string;
};

const RedirectWebMerchantFooter = ({
  onClick,
  text,
}: RedirectWebMerchantFooterProps) => {
  return (
    <BackToWebMerchantWrapper onClick={onClick}>
      <FooterBackToWebMerchant>{text}</FooterBackToWebMerchant>
      <SVGIconWrapper>
        <base-icon
          className="open-in-new"
          icon="open-in-new"
          viewBox="0 0 24 24"
        />
      </SVGIconWrapper>
    </BackToWebMerchantWrapper>
  );
};

export default RedirectWebMerchantFooter;
