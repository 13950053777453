import styled from 'styled-components';
import { TAB } from '../../utils/css-declinaisons';

export const StyledH1 = styled.h1`
  color: var(--neutrals-700);
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h1};
  letter-spacing: 0;
  margin-top: 0.4rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xxs};
  line-height: 3.2rem;
  font-family: 'Ubuntu Bold';
  @media screen and ${TAB} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;

export const StyledH2 = styled.h2`
  color: var(--neutrals-700);
  font-size: 2.4rem;
  letter-spacing: 0;
  margin-top: 0.4rem;
  margin-bottom: 0.8rem;
  line-height: 3.2rem;
  font-family: 'Ubuntu bold', sans-serif;
  @media screen and ${TAB} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;

export const StyledH3 = styled.h3`
  color: var(--neutrals-700);
  font-size: ${({ theme }) => theme.variables.fonts.sizes.h3};
  letter-spacing: 0;
  margin-top: 0.4rem;
  margin-bottom: ${({ theme }) => theme.variables.layout.spacing.spacing_xxs};
  line-height: 2.2rem;
  font-family: 'Ubuntu bold';
`;
