import styled from 'styled-components';
import { DESKTOP } from '../../utils/css-declinaisons';

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  margin: ${({ theme }) => theme.variables.layout.spacing.spacing_xs} 0 0 0;
  flex-grow: 1;
  @media screen and ${DESKTOP} {
    padding: 0 1.6rem;
  }
`;
export const HeaderWrapper = styled.div`
  align-items: left;
  text-align: left;
`;
