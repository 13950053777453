import { useManagingStep, useUrlWebMarchand } from '@sweb-front/hooks';
import { updateOpportunityStatus, useAppDispatch } from '@sweb-front/store';
import { trackEvent } from '@sweb-front/utils';
import { ERRORPAGE, OPPORTUNITYSTATUS } from '@vat/configuration';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useModalBadFormat = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { goToWebMarchand } = useManagingStep();
  const { getUrlCancel } = useUrlWebMarchand();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    trackEvent({
      event: 'popinDisplay',
      modaleName: 'E-Commerce : Formulaire KYC : nom/prénom à corriger',
    });
  }, []);

  const returnToUrlWebMerchant = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    goToWebMarchand(
      getUrlCancel(),
      'du lien "Accéder au site marchand",caractères spéciaux non supportés sur le nom ou/et prénom',
      () => {
        dispatch(updateOpportunityStatus(OPPORTUNITYSTATUS.ANUL));
      },
      () => {
        navigate(ERRORPAGE);
      }
    );
  }, [isLoading]);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    returnToUrlWebMerchant,
  };
};

export default useModalBadFormat;
