import { jwtDecode, JwtPayload } from 'jwt-decode';
import {
  clearNavigationStore,
  clearStore,
  fetchOpportunity,
  fetchWay,
  getActiveStep,
  getStepByExternalAppName,
  INavigationState,
  setRoutingContexts,
  setSteps,
  updateSteps,
  updateOpportunityStatus,
  useAppDispatch,
  IStep,
  setRightToEcard,
  IAppStep,
  setVatType,
  isOpenBankingResultOk,
  updateParcoursNavigation,
  setEndParcours,
} from '@sweb-front/store';
import { getTheme, LoadingWrapper } from '@sweb-front/styles';
import {
  IThreeDsResponse,
  Monext3DsResponse,
  MonextResponse,
} from '@sweb-front/types';
import {
  ascSort,
  isEmptySearchParams,
  isSearchParamsHasKey,
  isStringEmpty,
  isUrlContainsRedirectionParams,
  trackCustomAction,
} from '@sweb-front/utils';
import {
  APPPARAMS,
  COOKIES,
  ERRORKEY,
  ERRORPAGE,
  INFORMATIONBANCAIRES,
  LIVENESS,
  MENTIONSLEGALES,
  MENTIONSLEGALES100,
  MENTIONSLEGALES140,
  OPENBANKINGCHOICE,
  PIECESJUSTIFICATIVES,
  OPENBANKING,
  REFUSEDREPONSE,
  SMSMENTIONLEGALE,
  SUCCESSREPONSE,
  WAITINGRESPONSE,
  OBCONNEXIONERROR,
  THREEDS,
  REDIRECTION,
} from '@vat/configuration';
import {
  AppOptions,
  updateRoutingContexts,
  useCloseOpportunity,
  useManagingExternalApp,
} from '@vat/utils';

import { t } from 'i18next';
import { Loader } from '@sweb-front/components';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  clearLocalStorageData,
  computeSteps,
  fetchOpportunityFromBase,
  regexp,
} from './App';
import axios from 'axios';
import { formatToBnpString, postData } from 'src/containers/forms/utils';
import { useCreditParameters } from '@sweb-front/hooks';
export interface IAppConfig {
  msBffBaseURL: string;
  timeout: number;
  env: string;
  apimKey: string;
  api: IAppApiConfig;
}

export interface IAppApiConfig {
  openBankingRequest: IAppOpenBanking;
}

export interface IAppOpenBanking {
  basePath: string;
  init: string;
  result: string;
}

export type TToken = JwtPayload & {
  rightToEcard: string;
  iae?: string;
};

declare global {
  interface WindowWithEnv extends Window {
    _env_: IAppConfig;
  }
}

const useApp = (
  searchParams: URLSearchParams,
  steps: IStep[],
  appNavigation: INavigationState,
  storedToken: string | undefined,
  ihmSector?: string
) => {
  const isRibRegExp = new RegExp('vatr', 'i');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const navSteps = appNavigation?.steps;

  const [theme, setTheme] = useState(getTheme());
  const [isWaitingPage, setWaitingPage] = useState<boolean>(false);
  const [waitingStep, setWaitingStep] = useState<string>('');
  const [numVendor, setNumVendor] = useState<string>('');
  const [paramsWaitingPage, setParamsWaitingPage] = useState<unknown>();
  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepWithSubStep, setCurrentStepWithSubStep] = useState(1);
  const [isPageWithNoHeader, setIsPageWithNoHeader] = useState<boolean>();
  const [isPageWithNoFooter, setIsPageWithNoFooter] = useState(true);
  const [internalPageLoading, setInternalPageLoading] = useState(true);
  let lastPage = location.pathname;
  const activeStep = getActiveStep(navSteps);
  const ENCO = 'ENCO';

  const [containedError, setContainedError] = useState(false);
  const { initMonext, init3DS, initOnBo, goToExternalAppByForm } =
    useManagingExternalApp();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const { recalculateCreditParameters } = useCreditParameters();
  const { updateAbortErrorMessage } = useCloseOpportunity();

  const updatePageIsLoading = (state: boolean) => {
    setInternalPageLoading(state);
  };

  const FREEPAGES = [
    MENTIONSLEGALES,
    MENTIONSLEGALES100,
    MENTIONSLEGALES140,
    COOKIES,
    SMSMENTIONLEGALE,
  ];

  const [appOptions, setAppOptions] = useState<AppOptions>({
    isMounted: false,
  });
  useEffect(() => {
    if (
      location &&
      ![
        '/informations',
        '/informations-bancaires',
        '/erreur',
        '/revenus-charges',
        '/options',
        '/situation-familiale',
        '/',
      ].includes(location.pathname)
    ) {
      setAppOptions((state) => ({ ...state, isMounted: true }));
    }
    return () => {
      setAppOptions((state) => ({ ...state, isMounted: false }));
    };
  }, []);

  const updateError = useCallback(() => {
    setContainedError(true);
  }, []);

  useEffect(() => {
    setWaitingPage(regexp.test(lastPage));
    setContainedError(false);

    // Clear the storage first
    localStorage.removeItem(ERRORKEY);
  }, []);

  // Hide the stepline (ariane) when the these pages are requested
  useEffect(() => {
    const withNoHeader = [
      SUCCESSREPONSE,
      REFUSEDREPONSE,
      REDIRECTION,
      PIECESJUSTIFICATIVES,
      ...FREEPAGES,
      ERRORPAGE,
      INFORMATIONBANCAIRES,
      WAITINGRESPONSE,
      OPENBANKINGCHOICE,
      LIVENESS,
      OPENBANKING,
      OBCONNEXIONERROR,
      SMSMENTIONLEGALE,
    ].includes(lastPage);
    setIsPageWithNoHeader(withNoHeader);

    const withNoFooter =
      [
        MENTIONSLEGALES,
        SMSMENTIONLEGALE,
        COOKIES,
        ERRORPAGE,
        INFORMATIONBANCAIRES,
        PIECESJUSTIFICATIVES,
        OPENBANKINGCHOICE,
        OPENBANKING,
        OBCONNEXIONERROR,
        REDIRECTION,
      ].includes(lastPage) || regexp.test(lastPage);
    setIsPageWithNoFooter(withNoFooter);
  }, [location]);

  const readParameters = useCallback(
    async (
      distributorNb: string,
      overdraftAmt: string,
      productTypeCd: string,
      openBankingChoice?: string,
      openBankingStatus?: string
    ) => {
      if (!distributorNb || !overdraftAmt || !productTypeCd) {
        setContainedError(true);
        updateAbortErrorMessage(
          `useApp::readParameters problème au niveau des données du paramétrages
            distributor: "${distributorNb}";
            overdraftAmt: "${overdraftAmt}";
            productTypeCd: "${productTypeCd}";
           `
        );
        return;
      }
      try {
        const { result, appSteps } = await recalculateCreditParameters(
          distributorNb,
          overdraftAmt,
          productTypeCd,
          openBankingChoice,
          openBankingStatus
        );
        dispatch(fetchWay(result));
        dispatch(setVatType(result?.wayCd.toLowerCase()));
        if (appSteps && appSteps.length > 0) {
          dispatch(setSteps(appSteps));
        } else {
          updateAbortErrorMessage(
            'useApp::readParameters Les étapes retournées par le paramétrage sont vides'
          );
          setContainedError(true);
        }
        setIsAppLoading(false);
      } catch {
        updateAbortErrorMessage(
          "useApp::readParameters La lecture du paramétrage ne s'est pas faite correctement"
        );
        setContainedError(true);
        setIsAppLoading(false);
      }
    },
    []
  );

  const continueTreatment = (
    navigationStore,
    page: string,
    urlParams: URLSearchParams,
    lastActiveStep: IAppStep,
    applicationSteps: IAppStep[],
    containedError: boolean
  ) => {
    if (
      page === ERRORPAGE ||
      containedError ||
      FREEPAGES.includes(page) ||
      (!urlParams.has('token') && (applicationSteps ?? []).length < 1)
    ) {
      setIsAppLoading(false);
      window.history.replaceState({}, document.title, page);
      return;
    }

    /**  ----------  CAS  MONEXT  --------- */
    if (lastActiveStep && lastActiveStep.externalAppName === 'monext') {
      // créer une nouvelle redirection monext
      if (isStringEmpty(lastActiveStep.externalUrl)) {
        initMonext();
        return;
      }

      if (
        !isSearchParamsHasKey(urlParams, 'redirectFromMonext') ||
        (isSearchParamsHasKey(urlParams, 'redirectFromMonext') &&
          urlParams.get('returnCode') === '12')
      ) {
        // Cas où Monext est le premier débranchement, on peut le rediriger vers notre appli
        if ((applicationSteps ?? []).length === 1) {
          dispatch(
            updateSteps({
              externalAppName: 'monext',
              isExternalUrlAlreadyVisited: false,
            })
          );
          navigate(
            lastActiveStep.isExternalUrlAlreadyVisited
              ? navigationStore.lastAppUrl
              : page
          );
          setIsAppLoading(false);
        } else {
          dispatch(
            updateSteps({
              externalAppName: 'monext',
              isExternalUrlAlreadyVisited: true,
            })
          );
          // sinon on le redirige vers monext avec les étapes déja faites
          goToExternalAppByForm(lastActiveStep);
        }
        return;
      }
    }

    /**  ----------  CAS ONBO  --------- */
    if (lastActiveStep && lastActiveStep.externalAppName === 'onbo') {
      // Créer une url onbo
      if (isStringEmpty(lastActiveStep.externalUrl)) {
        initOnBo();
        return;
      }

      // Cas où ONBO est le premier débranchement, on peut encore parcourir l'application si pas de SE
      if (
        (applicationSteps ?? []).length === 1 &&
        lastActiveStep.isExternalUrlAlreadyVisited
      ) {
        dispatch(
          updateSteps({
            externalAppName: 'onbo',
            isExternalUrlAlreadyVisited: false,
          })
        );
        navigate(navigationStore.lastAppUrl);
        setIsAppLoading(false);
        return;
      }

      // Cas où ONBO est à une étape quelconque, on le redirige vers onbo
      if (
        !isStringEmpty(lastActiveStep.externalUrl) &&
        lastActiveStep.isExternalUrlAlreadyVisited &&
        !isSearchParamsHasKey(urlParams, 'redirectFromSignature')
      ) {
        dispatch(
          updateSteps({
            externalAppName: 'onbo',
            isExternalUrlAlreadyVisited: true,
          })
        );
        window.location.replace(lastActiveStep.externalUrl!);
        return;
      }
    }

    /**  ----------  CAS  3DS  --------- */
    /**
     * A noter ici que 3DS dépend du résultat onbo, du coup nous avons besoin des informations en réponse onbo
     * pour pouvoir initier 3DS
     *
     */

    if (lastActiveStep && lastActiveStep.externalAppName === '3ds') {
      // créer une nouvelle url 3DS
      if (isStringEmpty(lastActiveStep.externalUrl)) {
        init3DS(
          getStepByExternalAppName('onbo', applicationSteps)
            ?.redirectionParam ?? {},
          () => {
            dispatch(
              updateSteps({
                externalAppName: 'onbo',
                isSeDone: true,
                redirectionParam: 'redirectFromSignature',
              })
            );
          }
        );
        return;
      }

      if (
        !isSearchParamsHasKey(urlParams, 'redirectFrom3DS') &&
        isEmptySearchParams(urlParams, APPPARAMS.redirectFrom3DS)
      ) {
        try {
          goToExternalAppByForm(lastActiveStep);
        } catch {
          updateAbortErrorMessage(
            "3DS:: submitForm, we can't init twice the form even with the same value"
          );
          dispatch(
            updateSteps({
              externalAppName: '3ds',
              isErrorHappened: true,
            })
          );
          setContainedError(true);
        }
      }
    }

    /**  ----------  CAS  OPENBANKING  --------- */
    if (lastActiveStep && lastActiveStep.externalAppName === 'openBanking') {
      if (!isStringEmpty(activeStep?.externalUrl)) {
        dispatch(
          updateSteps({
            externalAppName: 'openBanking',
            isExternalUrlAlreadyVisited: true,
          })
        );
        return window.location.replace(activeStep?.externalUrl);
      }
    }

    /**  ----------  CAS  doAuth  --------- */
    if (lastActiveStep && lastActiveStep.externalAppName === 'authorization') {
      dispatch(
        updateSteps({
          externalAppName: '3ds',
          params: paramsWaitingPage,
          isErrorHappened: false,
          isSeDone: true,
        })
      );
      dispatch(
        updateParcoursNavigation({
          name: THREEDS,
          actionPageDone: true,
        })
      );
      navigate(REDIRECTION, {
        replace: true,
      });
    }

    return setIsAppLoading(false);
  };

  const readOpportunities = useCallback(
    async (
      nav: INavigationState,
      params: URLSearchParams,
      storedToken?: string
    ) => {
      const hasParamsToken = params.has('token');
      const tokenParam = hasParamsToken ? params.get('token') : storedToken;
      const isNewToken =
        hasParamsToken && params.get('token') !== (storedToken ?? '');

      if (isNewToken) {
        clearStore();
        clearLocalStorageData();
        clearNavigationStore();
        localStorage.setItem('token', tokenParam as string);
      }

      setIsAppLoading(true);

      fetchOpportunityFromBase(
        tokenParam,
        async (response) => {
          let opportunityStatus = response.data.statusCd;

          dispatch(updateOpportunityStatus(opportunityStatus));
          // Update routing context
          dispatch(
            setRoutingContexts(
              updateRoutingContexts(
                response?.data?.routingContexts ?? [],
                isNewToken ? [] : nav?.routingContexts ?? []
              )
            )
          );

          // Check if the opportunity is not cancelled (ANUL)
          if (opportunityStatus === 'ANUL') {
            dispatch(setEndParcours(true));
            setIsAppLoading(false);
            return setContainedError(true);
          } else if (opportunityStatus === 'APPR') {
            dispatch(setEndParcours(true));
            setIsAppLoading(false);
            return navigate(SUCCESSREPONSE, {
              replace: true,
            });
          } else if (opportunityStatus === 'REFU') {
            dispatch(setEndParcours(true));
            setIsAppLoading(false);
            navigate(REFUSEDREPONSE, {
              replace: true,
            });
          }
          // Put here to avoid that user can use the same token on another browser and he can change the user
          else {
            if (isNewToken && opportunityStatus === 'NEW') {
              axios.defaults.headers.common.token = tokenParam;
              await postData(
                'vendors/opportunities/v1/opportunity',
                {
                  statusCd: ENCO,
                },
                () => {
                  const decodedToken = jwtDecode(tokenParam) as TToken;
                  dispatch(
                    setRightToEcard(decodedToken.rightToEcard === 'true')
                  );
                  opportunityStatus = ENCO;
                  dispatch(updateOpportunityStatus(ENCO));
                  window.history.replaceState({}, document.title, '/');
                },
                () => {
                  setIsAppLoading(false);
                  setContainedError(true);
                }
              );
            }

            // Displays the response page if we have already finish the loan request with the response page
            if (nav?.endParcours && !isNewToken) {
              if (opportunityStatus === 'PAPP') {
                navigate(WAITINGRESPONSE, {
                  replace: true,
                });
              } else {
                navigate(ERRORPAGE, {
                  replace: true,
                });
              }
              return setIsAppLoading(false);
              /**
               * Pour le moment on peut seulement conditionner cet état
               * car pour le statut REFU et PAPP, nous ne sommes pas sûrs que le parcours il est terminé
               * pour le cas REFU, sur orange on peut avoir un statut refus avant même de faire le final instant decision
               * et du coup on ne peut pas le conditionner ici
               */
            } else if (
              ![ENCO, 'PAPP'].includes(opportunityStatus) &&
              !isUrlContainsRedirectionParams(params)
            ) {
              setIsAppLoading(false);
              return setContainedError(true);
            } else if (isUrlContainsRedirectionParams(params)) {
              return redirectToWaitingPage(params, nav);
            }

            const personRes = response.data.persons?.[0];
            const { personalInformation } = personRes;
            if (
              personalInformation &&
              personalInformation.birthCountryIsoCd &&
              personalInformation.birthCountryIsoCd !== 'FR'
            ) {
              personRes.personalInformation.birthCityInseeCd = '';
              personRes.personalInformation.birthDepartment = '';
            }

            personRes.personalInformation.nationalityIsoCd = personRes
              .personalInformation.nationalityIsoCd
              ? personRes.personalInformation.nationalityIsoCd
              : 'FR';

            personRes.personalInformation.birthName = formatToBnpString(
              personRes.personalInformation.birthName,
              30
            );

            const { overdraftAmt } =
              response.data.offers[0].loans[0].financialDetails;

            // When the user does not still save and for any reason he wants to refresh the page,
            // we have to clear the field otherwise we put the last activity he chose.
            // The reason why we do that is that the activitySector is computed from the back
            // and the SPA does not get the exact activitySector value when we load the opporunity.
            let activitySector = '';
            if (
              !isNewToken &&
              !isStringEmpty(personRes?.profession?.activitySectorCd)
            ) {
              activitySector = ihmSector;
            }

            dispatch(
              fetchOpportunity({
                distributor: response.data.distributor ?? {},
                person: {
                  ...personRes,
                  profession: !isStringEmpty(activitySector)
                    ? {
                        ihmSector: activitySector,
                      }
                    : null,
                },
                opportunityIdExt: response.data.opportunityIdExt ?? {},
                project: response.data.project ?? {},
                offers: response.data.offers ?? [],
              })
            );

            const { distributorNb } = response.data.distributor;
            setNumVendor(distributorNb);

            const { productTypeCd } =
              response.data.offers[0].loans[0].financialProduct;

            if (isNewToken) {
              readParameters(distributorNb, overdraftAmt, productTypeCd);
            }

            if (!isNewToken) {
              continueTreatment(
                nav,
                lastPage,
                params,
                activeStep,
                navSteps,
                containedError
              );
            } else {
              navigate('/');
            }

            setIsAppLoading(false);
          }
        },
        (e) => {
          setIsAppLoading(false);
          setContainedError(true);
        }
      );
    },
    [lastPage, appNavigation]
  );

  useEffect(() => {
    setTheme(getTheme());

    if (FREEPAGES.includes(lastPage)) {
      setIsAppLoading(false);
      return;
    }

    //  XTN: This information is crucial to have a fine-grained machine to machine integration with SEAP server component
    window.seapweb?.getTokenId();

    // Displays error page after page refresh if an error already occured in the application
    if (
      (![ENCO, 'PAPP', 'APPR'].includes(appNavigation?.opportunityStatus) &&
        !appNavigation?.endParcours &&
        !searchParams.has('token')) ||
      (!searchParams.has('token') &&
        getActiveStep(appNavigation?.steps ?? [])?.isErrorHappened)
    ) {
      setIsAppLoading(false);
      setContainedError(true);
    }

    // Lancer seulement la lecture d'affaire sur les pages qui le requièrent
    readOpportunities(appNavigation, searchParams, storedToken);

    return () => {
      setIsAppLoading(false);
    };
  }, []);

  /**
   * This method is used to get the return url method done by external applications (Monext, OnBo, 3DS)
   * So what happenned if user tape it manually on navigator ??
   */
  const redirectToWaitingPage = (
    urlParam: URLSearchParams,
    applicationNavigation: INavigationState
  ) => {
    setWaitingPage(false);
    let step;
    let parameterName;
    const activeStep = getActiveStep(applicationNavigation?.steps);

    if (
      urlParam.has('redirectFromMonext') &&
      activeStep?.externalAppName === 'monext'
    ) {
      // Redirect user directly to onbo if an url already exists on the store
      parameterName = 'redirectFromMonext';
      step = 'attente-cb';
      const monextResponse: MonextResponse = {
        cardId: urlParam.get('cardId') ?? '',
        cardRef: urlParam.get('cardRef') ?? '',
        returnCode: urlParam.get('returnCode') ?? '',
        returnValue: urlParam.get('returnValue') ?? '',
        sign: (urlParam.get('sign') as string) ?? '',
      };
      setParamsWaitingPage(monextResponse);
    } else if (
      urlParam.has('redirectFromSignature') &&
      activeStep?.externalAppName === 'onbo'
    ) {
      const isVatRib = isRibRegExp.test(applicationNavigation?.vatType ?? '');
      localStorage.removeItem('urlOnboarding');
      parameterName = 'redirectFromSignature';
      step = !!isVatRib ? 'attente-onboarding-rib' : 'attente-onboarding';
      const threeDsResponse: IThreeDsResponse = {
        returnCode: urlParam.get('onboardingError') ?? '',
        sign: urlParam.get('signed') ?? '',
        storedSign: applicationNavigation?.randomSign,
      };
      setParamsWaitingPage(threeDsResponse);
    } else if (
      urlParam.has('redirectFrom3DS') &&
      activeStep?.externalAppName === '3ds'
    ) {
      parameterName = 'redirectFrom3DS';
      step = 'attente-3ds';
      const monext3DsResponse: Monext3DsResponse = {
        returnCode: urlParam.get('returnCode') ?? '',
        returnValue: urlParam.get('returnValue') ?? '',
        sign: (urlParam.get('sign') as string) ?? '',
        threeDsRef: (urlParam.get('threeDsRef') as string) ?? '',
      };
      setParamsWaitingPage(monext3DsResponse);
    } else if (
      urlParam.has('redirectFromOpenBanking') &&
      activeStep?.externalAppName === 'openBanking'
    ) {
      parameterName = 'redirectFromOpenBanking';
      step = 'attente-openbanking';
    }

    if (parameterName) {
      const token = localStorage.getItem('token') ?? '';
      let decodedToken = '';
      try {
        decodedToken = (jwtDecode(token) as TToken)?.iae;
      } catch {
        decodedToken = '';
      }
      const waitingPageUrl = `/to/${step}?${parameterName}`;
      trackCustomAction(
        `opportunityIdExt:${decodedToken} :: Redirection data tracked: ${location.pathname} - ${location.search}`
      );
      setWaitingPage(true);
      setWaitingStep(step ?? '');
      navigate(waitingPageUrl);
      setIsAppLoading(false);
    } else {
      continueTreatment(
        appNavigation,
        lastPage,
        urlParam,
        activeStep,
        navSteps,
        containedError
      );
    }
  };

  // Displays the correct page on the browser within the given steps
  useEffect(() => {
    (steps ?? []).forEach((st) => {
      if (st.paths.includes(location.pathname)) {
        const stepsToDisplay = computeSteps(steps);
        const allSteps = computeStepsWithSubSteps(steps);
        // setCurrentStepWithSubStep
        const currentStepForStepper = allSteps.find(
          (cs) => cs.name === st.paths[0]
        );
        setCurrentStepWithSubStep(currentStepForStepper.step);
        if (!st.isSubStep) {
          const [computedStep] = stepsToDisplay.filter((cs) => cs.id === st.id);
          setCurrentStep(computedStep.step);
        } else {
          const stepsWithPrevious = [...stepsToDisplay, st].sort((st1, st2) =>
            ascSort(st1.id, st2.id)
          );

          // Get the previous step that contains the substep and select its step
          const previousStepIndex =
            stepsWithPrevious.findIndex((stp) => stp.id === st.id) - 1;

          setCurrentStep(stepsWithPrevious[previousStepIndex]?.step);
        }
        document.title = t('pageHeader');
      }
    });
  }, [location, steps]);

  const isOpenBankingCurrentPage =
    (lastPage === '/' &&
      steps
        ?.find((stp) => stp.step === 1)
        ?.paths.includes(OPENBANKINGCHOICE)) ||
    [OPENBANKING, OPENBANKINGCHOICE, OBCONNEXIONERROR].includes(lastPage);

  const isErrorOccured =
    containedError ||
    (isWaitingPage && isStringEmpty(waitingStep)) ||
    (!searchParams.has('token') &&
      getActiveStep(appNavigation?.steps ?? [])?.isErrorHappened);

  const showHeader =
    !appNavigation?.endParcours &&
    !isErrorOccured &&
    !isPageWithNoHeader &&
    !regexp.test(lastPage) &&
    !isAppLoading &&
    !isOpenBankingCurrentPage &&
    !internalPageLoading;

  const showFooter =
    !internalPageLoading &&
    !isPageWithNoFooter &&
    !isAppLoading &&
    (!isOpenBankingCurrentPage ||
      [SUCCESSREPONSE, REFUSEDREPONSE, WAITINGRESPONSE, LIVENESS].includes(
        lastPage
      ));

  const LoaderCmp = (
    <LoadingWrapper>
      <Loader isLoading message={t('common.loading')} isTextInline />
    </LoadingWrapper>
  );

  /**
   * Compute the step to display on the stepline with its new step number
   * @param {IStep[]} steps
   * @return {}
   */
  const computeStepsWithSubSteps = (steps: IStep[]) => {
    let stepsToAdd = [];
    let visitedSteps = [];
    if (
      appNavigation.openBankingChoice === 'STANDARD' ||
      !isOpenBankingResultOk(appNavigation)
    ) {
      stepsToAdd = appNavigation?.appNavigationState;

      // get the list of pages based on the name
      const currentStepsName = steps.map((s) => s.paths[0]);

      //remove the duplicate
      visitedSteps = stepsToAdd?.filter(
        (s) => !currentStepsName.includes(s.name)
      );
    }

    //comnbine the steps
    const allStepsCombined = [...(visitedSteps ?? []), ...(steps ?? [])];

    return (allStepsCombined ?? []).map((s, index: number) => ({
      name: s.name || s.paths[0],
      step: index + 1,
    }));
  };

  return {
    t,
    theme,
    appOptions,
    isErrorOccured,
    isPageWithNoHeader,
    lastPage,
    isAppLoading,
    numVendor,
    currentStep,
    LoaderCmp,
    isWaitingPage,
    waitingStep,
    paramsWaitingPage,
    containedError,
    navSteps,
    showHeader,
    showFooter,
    readOpportunities,
    updatePageIsLoading,
    readParameters,
    redirectToWaitingPage,
    setAppOptions,
    updateError,
    // for test purpose
    continueTreatment,
    computeStepsWithSubSteps,
    currentStepWithSubStep,
  };
};

export default useApp;
