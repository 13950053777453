import styled from 'styled-components';
import { DESKTOP, TAB } from '@sweb-front/utils';

const CenteredContent = styled.div`
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.6rem;
  position: relative;

  @media screen and ${TAB} {
    padding: 0 15.2rem;
  }
  @media screen and ${DESKTOP} {
    padding: 0 1.6rem;
  }
`;

export interface ICenteringProps {
  children?: React.ReactNode;
  className?: string;
}

const Centering = ({
  children,
  className = '',
}: ICenteringProps): React.ReactElement => (
  <CenteredContent className={className}>{children}</CenteredContent>
);

export default Centering;
