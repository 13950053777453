import { useTranslation } from 'react-i18next';
import AutomaticAnalysysMethodCard from './items/AutomaticAnalysysMethodCard';
import StandardMethodCard from './items/StandardMethodCard';
import { StyledButtonLoader } from '@sweb-front/styles';
import { ButtonActionWrapper } from '@vat/utils';
import { StyledContinueButtonWrapper } from './items/style';
import ModalOpenBankingChoise from './items/modal/ModalOpenBankingChoise';
import { CookiesCheckbox } from '@vat/components';
import { InputWrapper } from './style';
import useOpenBankingChoiceForm from './useOpenBankingChoiceForm';
import { selectSteps, useAppSelector } from '@sweb-front/store';
import AutomaticAnalysysMethodCardVatRib from './items/AutomaticAnalysysMethodCardVatRib';
import StandardMethodCardVatRib from './items/StandardMethodCardVatRib';
import { ModalLeave } from '@vat/layouts';
export interface IOpenBankingChoiceFormProps {
  isVATRib: boolean;
}

const OpenBankingChoiceForm = ({ isVATRib }: IOpenBankingChoiceFormProps) => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const { person } = opportunity;
  const steps = useAppSelector(selectSteps);

  const {
    AUTOMATICMETHOD,
    STANDARDMETHOD,
    methodConsent,
    selectedMethod,
    handleSelectedMethod,
    openModal,
    closeModal,
    onUpdateMethodConsent,
    onSubmit,
    isFormValid,
    isActionLoading,
    showModal,
    showModalLeave,
    onShowModalLeaveFn,
  } = useOpenBankingChoiceForm(
    person?.personId ?? '',
    opportunity,
    steps ?? [],
    isVATRib
  );

  return (
    <>
      <form>
        {isVATRib ? (
          <AutomaticAnalysysMethodCardVatRib
            onSelect={handleSelectedMethod}
            isSelected={selectedMethod === AUTOMATICMETHOD}
            openModal={openModal}
          />
        ) : (
          <AutomaticAnalysysMethodCard
            onSelect={handleSelectedMethod}
            isSelected={selectedMethod === AUTOMATICMETHOD}
            openModal={openModal}
          />
        )}
        {isVATRib ? (
          <StandardMethodCardVatRib
            onSelect={handleSelectedMethod}
            isSelected={selectedMethod === STANDARDMETHOD}
          />
        ) : (
          <StandardMethodCard
            onSelect={handleSelectedMethod}
            isSelected={selectedMethod === STANDARDMETHOD}
          />
        )}

        {selectedMethod === AUTOMATICMETHOD && (
          <InputWrapper id={'wrapper-obsconsent'}>
            <CookiesCheckbox
              id="obConsent"
              name="obConsent"
              label={t('openBanking.automaticMethodMessage')}
              state={methodConsent}
              onChange={onUpdateMethodConsent}
            />
          </InputWrapper>
        )}

        <ButtonActionWrapper>
          <StyledContinueButtonWrapper
            onClick={onSubmit}
            $isDisabled={!isFormValid}
            disabled={!isFormValid}
            id="ob-conitniue-button"
            className={!isFormValid ? 'isDisabled' : ''}
          >
            {isActionLoading ? (
              <StyledButtonLoader
                isLoading
                isTextInline
                isHideBackground={false}
              />
            ) : (
              t('common.continue')
            )}
          </StyledContinueButtonWrapper>
        </ButtonActionWrapper>
        {showModal && <ModalOpenBankingChoise closeModal={closeModal} />}
      </form>
      {showModalLeave && (
        <ModalLeave
          title={t('LeavePage.title')}
          description={t('LeavePage.description')}
          descriptionProgress={t('LeavePage.descriptionProgress')}
          confirmation={t('LeavePage.confirmation')}
          close={onShowModalLeaveFn}
        />
      )}
    </>
  );
};

export default OpenBankingChoiceForm;
